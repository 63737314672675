<template>
  <section>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">新闻资讯</v-flex>
        <v-flex md6 class="hidden-sm-and-down"><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container" v-if="detail">
      <h3 class="article-title wowd fadeInUp">{{detail.title}}</h3>
      <div class="article-action wowd fadeInUp" data-wow-delay="0.2s">
        <span class="article-type">{{types[detail.type]}}</span>
        <span class="article-date">{{detail.create_time}}</span>
      </div>
      <div v-html="detail.content" class="article-content wowd fadeInUp" data-wow-delay="0.4s"></div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 4,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '新闻资讯', disabled: false, href: '/news' }
    ],
    types: { 2: '企业动态', 3: '媒体报道' },
    detail: null
  }),
  created () {
    this.getNew(this.$route.params)
    document.querySelector('#menu_news').classList.add('v-btn--active')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getNew (param) {
      this.https.get('new', param).then(response => {
        if (response.code === 0) {
          this.detail = response.data.detail
          document.title = this.detail.title + '-好睡眠'
          let type = response.data.detail.type
          this.breadcrumbs.push({ text: this.types[type], disabled: false, href: '/news/' + type })
          this.breadcrumbs.push({ text: this.detail.title.substring(0, 6) + '...', disabled: true })
        }
      }).then(_ => {
        this.$wowd.init()
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/article.css';
</style>
